<template>
  <surround-box>
    <div class="base-info-container">
      <div class="base-info-title">企业介绍</div>
      <div class="base-info-content-container">
        <div class="base-info-detail" v-html="content"></div>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox";

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      content: "<div style='color:#fff'><div>重庆农神控股(集团)有限公司</div></div>",
    };
  },
};
</script>
<style lang='less' scoped>
.base-info-container {
  width: 439px;
  background: linear-gradient(
    270deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
  .base-info-title {
    width: 100%;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(194, 226, 245, 0) 12%,
      #10a6bd 50%,
      rgba(210, 233, 247, 0) 90%,
      rgba(255, 255, 255, 0) 100%
    );
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .base-info-content-container {
    padding: 16px;
  }
}
</style>